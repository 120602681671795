import * as React from 'react'
import { Link } from 'gatsby'

const NotFoundPage = () => (
  <div style={{ backgroundColor: 'white', padding: 24 }}>
    <title>Not found</title>
    <h1>Page not found</h1>
    <p>
      Sorry{' '}
      <span role="img" aria-label="Pensive emoji">
        😔
      </span>{' '}
      we couldn’t find what you were looking for.
      <br />
      {process.env.NODE_ENV === 'development' ? (
        <>
          <br />
          Development error page
          <br />
        </>
      ) : null}
      <br />
      <Link to="/">Go home</Link>.
    </p>
  </div>
)

export default NotFoundPage
